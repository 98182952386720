/* eslint-disable */
// import React, { useState, useRef } from "react";
// import { useNavigate } from "react-router-dom";
// import CameraComponent from "../../components/Camera";
// import { ConfirmationModal } from "../../components/ConfirmationModal";
// import { CrossIcon } from "../../components/crossIcon";
// import { MARK_CHECK_IN_CHECK_OUT } from "../../constants/constants";
// import {
//   BASE_URL,
//   EMPLEDO_URL,
//   MARK_ATTENDANCE_DATA_URL,
// } from "../../constants/endpoints";
// import useApi from "../../hooks/useApi";
// import { useGeolocation } from "../../hooks/useGeolocation";
// import { MarkEmployeeDetails } from "../../layout/markEmployeeDetails";
// import {
//   DataURIToBlob,
//   getCurrentFormattedTime,
//   toUrlEncoded,
// } from "../../utils/functions";
// import "./Home.css";
// const cookie =
//   "ajs_anonymous_id=5f7da65f-d8ce-4885-9a1c-e4b6fbeed3ef; user_image=; sid=b0c2738436fd27be9f9260f0bed7fc9e7dced311a1af401e51c11cf4; system_user=yes; full_name=ARThink%20Admin; user_id=Administrator";

// const header = {
//   Accept: "application/json",
//   "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
//   "X-Frappe-CSRF-Token": process.env.REACT_APP_API_KEY_FRAPE,
//   Cookie: cookie,
//   Authorization: process.env.REACT_APP_API_KEY,
// };

// const BASE_URL_UPLOAD_PHOTO = process.env.REACT_APP_FILE_UPLOAD;

// const Home = () => {
//   const apiToken = process.env.REACT_APP_API_KEY;
//   const {
//     memoizedAddress,
//     loading: locationLoading,
//     latitude,
//     longitude,
//   } = useGeolocation();
//   const navigate = useNavigate();
//   const cameraRef = useRef(null); // Reference to control CameraComponent

//   const { loading, postData, fetchData } = useApi();
//   const [img, setImage] = React.useState();
//   const [markEmployeeDetails, setMarkEmployeeDetails] = useState(null);
//   const [showModal, setShowModal] = React.useState(false);
//   const [modalText, setModalText] = React.useState({
//     title: "Attendance Recorded",
//     message: "Attendence marked",
//     isSuccess: true,
//   });
//   const [captureEmpId, setCaptureEmpId] = React.useState(false);
//   const [manualEmpId, setManualEmpId] = React.useState("");
//   const [showLivelinessErrorModal, setShowLivelinessErrorModal] = React.useState(false);

//   const [startCamera, setStartCamera] = useState(true);
//   const [stopCamera, setStopCamera] = useState(false);

//   // eslint-disable-next-line
//   const [_empid, setEmpid] = React.useState(null);
//   React.useEffect(() => {
//     if (showModal) {
//       setTimeout(() => {
//         if (modalText?.redirect) {
//           navigate(modalText.redirect);
//         }
//         setShowModal(false);
//       }, 5000);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [showModal]);

//   const apiErrorHandler = (msg) => {
//     setShowModal(true);
//     setModalText((prev) => ({
//       ...prev,
//       title: "Server Error",
//       message: "Something went wrong",
//       isSuccess: false,
//     }));
//   };

//   // TODO: mark attendence code to be used in future
//   // const handleMarkAttendance = async (resData) => {
//   //   const { employee, employee_name, department, designation, company } =
//   //     resData?.docs[0];
//   //   let DATA = { ...MARK_ATTENDANCE_DATA };
//   //   DATA.doc.attendance_date = getTodayDate();
//   //   DATA.doc.employee_name = employee_name ?? "";
//   //   DATA.doc.company = company ?? "";
//   //   DATA.doc.department = department ?? "";
//   //   DATA.doc.designation = designation ?? "";
//   //   DATA.doc.employee = employee ?? "";
//   //   try {
//   //     const res = await postData(
//   //       "api/method/frappe.desk.form.save.savedocs",
//   //       DATA,
//   //       false,
//   //       {
//   //         Authorization: apiToken,
//   //         Accept: "application/json",
//   //         "Content-Type": "application/json",
//   //       },
//   //       EMPLEDO_URL
//   //     );
//   //     if (res?.exc_type === "DuplicateAttendanceError") {
//   //       setShowModal(true);
//   //       setModalText((prev) => ({
//   //         ...prev,
//   //         message: `${employee_name} attendance already marked !!!`,
//   //         isSuccess: true,
//   //       }));
//   //     } else {
//   //       setShowModal(true);
//   //       setModalText((prev) => ({
//   //         ...prev,
//   //         message: `${employee_name} attendance marked for ${getTodayDate()}`,
//   //         isSuccess: true,
//   //       }));
//   //     }
//   //   } catch (e) {
//   //     apiErrorHandler();
//   //   }
//   // };

//   const fetchEmpData = async (id) => {
//     try {
//       const res = await fetchData(
//         `api/method/frappe.desk.form.load.getdoc?doctype=Employee&name=${id}&_=1700716562378`,
//         {
//           Authorization: apiToken,
//           "Content-Type": "application/json",
//           Accept: "application/json",
//         },
//         EMPLEDO_URL
//       );
//       if (res?.docs && res?.docs?.length) {
//         // handleMarkAttendance(res);
//         setCaptureEmpId(false);
//         setMarkEmployeeDetails(res);
//       } else {
//         setShowModal(true);
//         setModalText((prev) => ({
//           ...prev,
//           title: "Sign-Up Required: Face Detected",
//           message: `An unidentified face has been detected. Please contact Admin.`,
//           isSuccess: false,
//         }));
//         // setTimeout(() => navigate("/signup"), 3000);
//         setTimeout(() => setCaptureEmpId(true), 3000);
//       }
//     } catch (e) {
//       apiErrorHandler("cdf");
//     }
//   };

//   const checkBlinkingOfEye = async (imageUrl) => {
//     const data = {
//       keywords: "eyes are open or not",
//       url: imageUrl,
//     };

//     try {
//       const response = await postData("identify-blink", data, false, {
//         "Content-Type": "application/json",
//         Accept: "application/json",
//       });

//       if (response && response?.answer !== undefined) {
//         return response?.answer;
//       } else {
//         throw new Error("Invalid response structure");
//       }
//     } catch (e) {
//       console.error("Error recognizing face:", e.message || e);
//       return null;
//     }
//   };

//   const handleFaceRecognize = async (imgBase64) => {
//     const file = DataURIToBlob(imgBase64);
//     setImage(imgBase64);
//     // uploadFileToBlob(imgBase64);
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const photoUrl = await handlePhotoUpload(imgBase64);
//       let resEyeBlink;
//       if (photoUrl) {
//         formData.append("imageUrl", photoUrl);
//         resEyeBlink = await checkBlinkingOfEye(photoUrl);
//         console.log("resEyeBlink", resEyeBlink);
//       }

//       if (!resEyeBlink) {
//         setShowLivelinessErrorModal(true);
//       } else {
//         setShowLivelinessErrorModal(false);
//         const res = await postData("recognize_employee", formData, true);
//         if (res.name === "Unknown") {
//           setShowModal(true);
//           setModalText((prev) => ({
//             ...prev,
//             title: "Unable to detect face",
//             message: `An unidentified face has been detected. Please contact Admin.`,
//             isSuccess: false,
//           }));
//           setTimeout(() => setCaptureEmpId(true), 3000);
//         } else if (res.name === "No face found") {
//           setShowModal(true);
//           setModalText((prev) => ({
//             ...prev,
//             title: "Capture Face Only!!!",
//             message: `No face detected. Please ensure your face is clearly visible within the frame before capturing.`,
//             isSuccess: false,
//           }));
//         } else {
//           if (res?.empid) {
//             setEmpid(res?.empid);
//             fetchEmpData(res?.empid);
//           } else {
//             setShowModal(true);
//             setModalText((prev) => ({
//               ...prev,
//               title: "Verified User",
//               message: `Welcome ${res?.name}`,
//               isSuccess: false,
//             }));
//             setStopCamera(true);
//           }
//         }
//       }
//     } catch (err) {
//       apiErrorHandler();
//     }
//   };

//   const handlePhotoUpload = async (imgBase64) => {
//     const formData = new FormData();
//     formData.append("file", imgBase64 ?? img);
//     try {
//       const response = await postData(
//         "api/empledo-upload-file/",
//         formData,
//         true,
//         {},
//         BASE_URL_UPLOAD_PHOTO
//       );
//       return response?.data?.image_url ?? "";
//     } catch (e) {
//       console.error("Image upload error");
//     }
//   };

//   const handleCheckClick = async (value, lat, lon) => {
//     const cookies =
//       "sid=b0c2738436fd27be9f9260f0bed7fc9e7dced311a1af401e51c11cf4";
//     setManualEmpId("");
//     const photoUrl = await handlePhotoUpload();
//     const custom_userimage = photoUrl ?? "";
//     // formData.append("employee_lat", lat);
//     const res = await postData(
//       "location",
//       { employee_lon: lon, employee_lat: lat },
//       false,
//       {
//         "Content-Type": "application/json",
//         Cookie: cookies,
//       }
//     );

//     const time = getCurrentFormattedTime();
//     const formBody = toUrlEncoded({
//       doc: {
//         ...MARK_CHECK_IN_CHECK_OUT,
//         time: time,
//         log_type: value,
//         employee_name: markEmployeeDetails.docs[0]?.first_name,
//         employee: markEmployeeDetails.docs[0]?.name,
//         status: res?.status,
//         custom_userimage: custom_userimage,
//       },
//       action: "Save",
//     });

//     try {
//       await postData(
//         MARK_ATTENDANCE_DATA_URL,
//         formBody,
//         true,
//         {
//           ...header,
//         },
//         EMPLEDO_URL
//       );

//       if (!Boolean(res?.status)) {
//         handleSendEmail(
//           `/send_email?type=location_check&name=${markEmployeeDetails.docs[0]?.first_name}&location=${lat},${lon}&to_email=${markEmployeeDetails.docs[0]?.leave_approver}`
//         );
//       }
//       setShowModal(true);
//       setModalText((prev) => ({
//         ...prev,
//         title: `CHECK-${value} Success`,
//         message: `${markEmployeeDetails.docs[0]?.first_name} checked-${value} at ${time}`,
//         isSuccess: true,
//         redirect: "/",
//       }));

//       setMarkEmployeeDetails(null);
//     } catch (e) {
//       apiErrorHandler();
//     }
//   };

//   const handleSendEmail = async (params) => {
//     try {
//       await fetchData(
//         params,
//         {
//           Authorization: apiToken,
//           "Content-Type": "application/json",
//           Accept: "application/json",
//         },
//         BASE_URL
//       );
//     } catch (e) {
//       // apiErrorHandler();
//       console.log("email send");
//     }
//   };

//   const handleLeaveFromEmployee = async (leaveInfoData, leaveType) => {
//     setManualEmpId("");
//     let data = {
//       ...leaveInfoData,
//     };
//     const photoUrl = await handlePhotoUpload();
//     const custom_userimage = photoUrl ?? "";

//     const formBody = toUrlEncoded({
//       doc: { ...data, custom_userimage: custom_userimage },
//       action: "Save",
//     });
//     try {
//       const response = await postData(
//         MARK_ATTENDANCE_DATA_URL,
//         formBody,
//         true,
//         {
//           ...header,
//         },
//         EMPLEDO_URL
//       );
//       setShowModal(true);
//       let dataModal = {
//         title: `Leave applied Successfully`,
//         message: `${markEmployeeDetails.docs[0]?.first_name} leave applied for ${leaveType} successfully`,
//         isSuccess: true,
//       };
//       if (response.exc_type === "OverlapError") {
//         dataModal.title = "Leave already applied";
//         dataModal.message = `${markEmployeeDetails.docs[0]?.first_name} leave already applied`;
//       } else if (response.exc_type === "ValidationError") {
//         dataModal.title = "Leave Not Available";
//         dataModal.message = `${markEmployeeDetails.docs[0]?.first_name} leave not available`;
//       } else {
//         let date = data.from_date;
//         if (data.leave_type === "Full Day") {
//           data = `from ${data.from_date} to ${data?.to_date ?? data.from_date}`;
//         }
//         handleSendEmail(
//           `/send_email?type=leave_approval&name=${markEmployeeDetails.docs[0]?.first_name}&type_of_leave=${data.leave_type} for ${leaveType} & date_of_leave=${date} &to_email=${data.leave_approver}`
//         );
//       }

//       setModalText((prev) => ({
//         ...prev,
//         ...dataModal,
//         redirect: "/",
//       }));
//       setMarkEmployeeDetails(null);
//     } catch (e) {
//       apiErrorHandler();
//     }
//   };

//   return (
//     <div className="home-container">
//       <div className="absolute p-[6px] z-[100] bg-[white] rounded-full top-[4%] right-[6.5%]">
//         <CrossIcon
//           handleCross={() => navigate("/")}
//           className="h-[20px] w-[20px]"
//         />
//       </div>
//       {/* <h1 className="mt-[5px]">Welcome to ARThink</h1> */}
//       {false ? (
//         <div>Allow access or unable to access the website</div>
//       ) : (
//         <>
//           <CameraComponent
//             loading={loading}
//             handleFaceRecognize={handleFaceRecognize}
//             showModal={showModal}
//             startCamera={startCamera}
//             stopCamera={stopCamera}
//           />
//         </>
//       )}
//       {Boolean(markEmployeeDetails) && (
//         <MarkEmployeeDetails
//           open={Boolean(markEmployeeDetails)}
//           handleCloseModal={() => setMarkEmployeeDetails(null)}
//           data={markEmployeeDetails}
//           img={img}
//           handleCheckClick={handleCheckClick}
//           handleLeaveFromEmployee={handleLeaveFromEmployee}
//           manualEmpId={manualEmpId}
//           memoizedAddress={memoizedAddress}
//           locationLoading={locationLoading}
//           latitude={latitude}
//           longitude={longitude}
//         />
//       )}
//       {showModal && (
//         <ConfirmationModal
//           open={showModal}
//           title={modalText.title}
//           message={modalText.message}
//           isSuccess={modalText.isSuccess}
//           handleCloseModal={() => setShowModal(false)}
//         />
//       )}

//       {showLivelinessErrorModal && (
//         <ConfirmationModal
//           open={showLivelinessErrorModal}
//           title="We couldn't recognize your face."
//           message="Please keep your eyes open and look directly at the camera."
//           isSuccess={modalText.isSuccess}
//           handleCloseModal={() => setShowLivelinessErrorModal(false)}
//         />
//       )}

//       {/* {captureEmpId && (
//         <Modal
//           onClose={() => {
//             setCaptureEmpId(false);
//             setCaptureEmpId("");
//           }}
//           show={true}
//           title={"Enter your Employee ID"}
//           isFooterVisible={true}
//           onClickFooter={() => fetchEmpData(manualEmpId)}
//           footerButtonName={"Submit"}
//           isFooterDisabled={manualEmpId.length < 5}
//         >
//           <input
//             onChange={(e) => setManualEmpId(e.target.value)}
//             placeholder="Enter Employee Id"
//             className=""
//           />
//         </Modal>
//       )} */}
//     </div>
//   );
// };

// export default Home;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CameraComponent from "../../components/Camera";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { CrossIcon } from "../../components/crossIcon";
import { MARK_CHECK_IN_CHECK_OUT } from "../../constants/constants";
import {
  BASE_URL,
  EMPLEDO_URL,
  MARK_ATTENDANCE_DATA_URL,
} from "../../constants/endpoints";
import useApi from "../../hooks/useApi";
import { useGeolocation } from "../../hooks/useGeolocation";
import { MarkEmployeeDetails } from "../../layout/markEmployeeDetails";
import {
  DataURIToBlob,
  getCurrentFormattedTime,
  toUrlEncoded,
} from "../../utils/functions";
import "./Home.css";
import SuccessGIF from "../../assets/success.gif";

const cookie =
  "ajs_anonymous_id=5f7da65f-d8ce-4885-9a1c-e4b6fbeed3ef; user_image=; sid=b0c2738436fd27be9f9260f0bed7fc9e7dced311a1af401e51c11cf4; system_user=yes; full_name=ARThink%20Admin; user_id=Administrator";

const header = {
  Accept: "application/json",
  "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
  "X-Frappe-CSRF-Token": process.env.REACT_APP_API_KEY_FRAPE,
  Cookie: cookie,
  Authorization: process.env.REACT_APP_API_KEY,
};

const BASE_URL_UPLOAD_PHOTO = process.env.REACT_APP_FILE_UPLOAD;

const Home = () => {
  const apiToken = process.env.REACT_APP_API_KEY;
  const {
    memoizedAddress,
    loading: locationLoading,
    latitude,
    longitude,
  } = useGeolocation();
  const navigate = useNavigate();
  const { loading, postData, fetchData } = useApi();
  const [img, setImage] = React.useState();
  const [markEmployeeDetails, setMarkEmployeeDetails] = useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [modalText, setModalText] = React.useState({
    title: "Attendance Recorded",
    message: "Attendence marked",
    isSuccess: true,
  });
  const [captureEmpId, setCaptureEmpId] = React.useState(false);
  const [manualEmpId, setManualEmpId] = React.useState("");
  const [showLivelinessErrorModal, setShowLivelinessErrorModal] =
    React.useState(false);
  const [showLivelinessSuccessModal, setShowLivelinessSuccessModal] =
    React.useState(false);
  const [modalAnimationClass, setModalAnimationClass] =
    React.useState("modal-enter");

  // eslint-disable-next-line
  const [_empid, setEmpid] = React.useState(null);
  React.useEffect(() => {
    if (showModal || showLivelinessErrorModal) {
      setTimeout(() => {
        if (modalText?.redirect) {
          navigate(modalText.redirect);
        }
        setShowModal(false);
        setShowLivelinessErrorModal(false);
      }, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal, showLivelinessErrorModal]);

  const apiErrorHandler = (msg) => {
    setShowModal(true);
    setModalText((prev) => ({
      ...prev,
      title: "Server Error",
      message: "Something went wrong",
      isSuccess: false,
    }));
  };

  // TODO: mark attendence code to be used in future
  // const handleMarkAttendance = async (resData) => {
  //   const { employee, employee_name, department, designation, company } =
  //     resData?.docs[0];
  //   let DATA = { ...MARK_ATTENDANCE_DATA };
  //   DATA.doc.attendance_date = getTodayDate();
  //   DATA.doc.employee_name = employee_name ?? "";
  //   DATA.doc.company = company ?? "";
  //   DATA.doc.department = department ?? "";
  //   DATA.doc.designation = designation ?? "";
  //   DATA.doc.employee = employee ?? "";
  //   try {
  //     const res = await postData(
  //       "api/method/frappe.desk.form.save.savedocs",
  //       DATA,
  //       false,
  //       {
  //         Authorization: apiToken,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       EMPLEDO_URL
  //     );
  //     if (res?.exc_type === "DuplicateAttendanceError") {
  //       setShowModal(true);
  //       setModalText((prev) => ({
  //         ...prev,
  //         message: `${employee_name} attendance already marked !!!`,
  //         isSuccess: true,
  //       }));
  //     } else {
  //       setShowModal(true);
  //       setModalText((prev) => ({
  //         ...prev,
  //         message: `${employee_name} attendance marked for ${getTodayDate()}`,
  //         isSuccess: true,
  //       }));
  //     }
  //   } catch (e) {
  //     apiErrorHandler();
  //   }
  // };

  const fetchEmpData = async (id) => {
    try {
      const res = await fetchData(
        `api/method/frappe.desk.form.load.getdoc?doctype=Employee&name=${id}&_=1700716562378`,
        {
          Authorization: apiToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        EMPLEDO_URL
      );
      if (res?.docs && res?.docs?.length) {
        // handleMarkAttendance(res);
        setCaptureEmpId(false);
        setMarkEmployeeDetails(res);
      } else {
        setShowModal(true);
        setModalText((prev) => ({
          ...prev,
          title: "Sign-Up Required: Face Detected",
          message: `An unidentified face has been detected. Please contact Admin.`,
          isSuccess: false,
        }));
        // setTimeout(() => navigate("/signup"), 3000);
        setTimeout(() => setCaptureEmpId(true), 3000);
      }
    } catch (e) {
      apiErrorHandler("cdf");
    }
  };

  const checkLivelinessOfPerson = async (imageUrl) => {
    const data = {
      url: imageUrl,
      // keywords: "eyes are open or not",
    };

    try {
      const response = await postData("liveness", data, false, {
        "Content-Type": "application/json",
        Accept: "application/json",
      });

      if (response && response?.answer !== undefined) {
        return response?.answer;
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (e) {
      console.error("Error recognizing face:", e.message || e);
      return null;
    }
  };

  const proceedAfterLivelinessCheck = async (formData) => {
    try {
      const res = await postData("recognize_employee", formData, true);
      if (res.name === "Unknown") {
        setShowModal(true);
        setModalText((prev) => ({
          ...prev,
          title: "Unable to detect face",
          message: `An unidentified face has been detected. Please contact Admin.`,
          isSuccess: false,
        }));
        setTimeout(() => setCaptureEmpId(true), 3000);
      } else if (res.name === "No face found") {
        setShowModal(true);
        setModalText((prev) => ({
          ...prev,
          title: "Capture Face Only!!!",
          message: `No face detected. Please ensure your face is clearly visible within the frame before capturing.`,
          isSuccess: false,
        }));
      } else {
        if (res?.empid) {
          setEmpid(res?.empid);
          fetchEmpData(res?.empid);
        } else {
          setShowModal(true);
          setModalText((prev) => ({
            ...prev,
            title: "Verified User",
            message: `Welcome ${res?.name}`,
            isSuccess: true,
          }));
        }
      }
    } catch (error) {
      apiErrorHandler();
    }
  };

  const handleFaceRecognize = async (imgBase64) => {
    const file = DataURIToBlob(imgBase64);
    setImage(imgBase64);
    // uploadFileToBlob(imgBase64);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const photoUrl = await handlePhotoUpload(imgBase64);
      let resLivelinessCheck;
      if (photoUrl) {
        formData.append("imageUrl", photoUrl);

        resLivelinessCheck = await checkLivelinessOfPerson(photoUrl);
        console.log("=========== resLivelinessCheck =========================");
        console.log(resLivelinessCheck);
        console.log("====================================");
      }

      if (!resLivelinessCheck) {
        setShowLivelinessErrorModal(true);
        setShowLivelinessSuccessModal(false);
      } else {
        setShowLivelinessErrorModal(false);
        setShowLivelinessSuccessModal(true);
        setModalAnimationClass("modal-enter");

        setTimeout(() => {
          setModalAnimationClass("modal-exit");
          setTimeout(() => {
            setShowLivelinessSuccessModal(false);
            proceedAfterLivelinessCheck(formData);
          }, 1000);
        }, 3000);
      }
    } catch (err) {
      apiErrorHandler();
    }
  };

  const handlePhotoUpload = async (imgBase64) => {
    const formData = new FormData();
    formData.append("file", imgBase64 ?? img);
    try {
      const response = await postData(
        "api/empledo-upload-file/",
        formData,
        true,
        {},
        BASE_URL_UPLOAD_PHOTO
      );
      return response?.data?.image_url ?? "";
    } catch (e) {
      console.error("Image upload error");
    }
  };

  const handleCheckClick = async (value, lat, lon) => {
    const cookies =
      "sid=b0c2738436fd27be9f9260f0bed7fc9e7dced311a1af401e51c11cf4";
    setManualEmpId("");
    const photoUrl = await handlePhotoUpload();
    const custom_userimage = photoUrl ?? "";
    // formData.append("employee_lat", lat);
    const res = await postData(
      "location",
      { employee_lon: lon, employee_lat: lat },
      false,
      {
        "Content-Type": "application/json",
        Cookie: cookies,
      }
    );

    const time = getCurrentFormattedTime();
    const formBody = toUrlEncoded({
      doc: {
        ...MARK_CHECK_IN_CHECK_OUT,
        time: time,
        log_type: value,
        employee_name: markEmployeeDetails.docs[0]?.first_name,
        employee: markEmployeeDetails.docs[0]?.name,
        status: res?.status,
        custom_userimage: custom_userimage,
      },
      action: "Save",
    });

    try {
      await postData(
        MARK_ATTENDANCE_DATA_URL,
        formBody,
        true,
        {
          ...header,
        },
        EMPLEDO_URL
      );

      if (!Boolean(res?.status)) {
        handleSendEmail(
          `/send_email?type=location_check&name=${markEmployeeDetails.docs[0]?.first_name}&location=${lat},${lon}&to_email=${markEmployeeDetails.docs[0]?.leave_approver}`
        );
      }
      setShowModal(true);
      setModalText((prev) => ({
        ...prev,
        title: `CHECK-${value} Success`,
        message: `${markEmployeeDetails.docs[0]?.first_name} checked-${value} at ${time}`,
        isSuccess: true,
        redirect: "/",
      }));

      setMarkEmployeeDetails(null);
    } catch (e) {
      apiErrorHandler();
    }
  };

  const handleSendEmail = async (params) => {
    try {
      await fetchData(
        params,
        {
          Authorization: apiToken,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        BASE_URL
      );
    } catch (e) {
      // apiErrorHandler();
      console.log("email send");
    }
  };

  const handleLeaveFromEmployee = async (leaveInfoData, leaveType) => {
    setManualEmpId("");
    let data = {
      ...leaveInfoData,
    };
    const photoUrl = await handlePhotoUpload();
    const custom_userimage = photoUrl ?? "";

    const formBody = toUrlEncoded({
      doc: { ...data, custom_userimage: custom_userimage },
      action: "Save",
    });
    try {
      const response = await postData(
        MARK_ATTENDANCE_DATA_URL,
        formBody,
        true,
        {
          ...header,
        },
        EMPLEDO_URL
      );
      setShowModal(true);
      let dataModal = {
        title: `Leave applied Successfully`,
        message: `${markEmployeeDetails.docs[0]?.first_name} leave applied for ${leaveType} successfully`,
        isSuccess: true,
      };
      if (response.exc_type === "OverlapError") {
        dataModal.title = "Leave already applied";
        dataModal.message = `${markEmployeeDetails.docs[0]?.first_name} leave already applied`;
      } else if (response.exc_type === "ValidationError") {
        dataModal.title = "Leave Not Available";
        dataModal.message = `${markEmployeeDetails.docs[0]?.first_name} leave not available`;
      } else {
        let date = data.from_date;
        if (data.leave_type === "Full Day") {
          data = `from ${data.from_date} to ${data?.to_date ?? data.from_date}`;
        }
        handleSendEmail(
          `/send_email?type=leave_approval&name=${markEmployeeDetails.docs[0]?.first_name}&type_of_leave=${data.leave_type} for ${leaveType} & date_of_leave=${date} &to_email=${data.leave_approver}`
        );
      }

      setModalText((prev) => ({
        ...prev,
        ...dataModal,
        redirect: "/",
      }));
      setMarkEmployeeDetails(null);
    } catch (e) {
      apiErrorHandler();
    }
  };

  return (
    <div className="home-container">
      <div className="absolute p-[6px] z-[100] bg-[white] rounded-full top-[4%] right-[6.5%]">
        <CrossIcon
          handleCross={() => navigate("/")}
          className="h-[20px] w-[20px]"
        />
      </div>
      {/* <h1 className="mt-[5px]">Welcome to ARThink</h1> */}
      {false ? (
        <div>Allow access or unable to access the website</div>
      ) : (
        <>
          <CameraComponent
            loading={loading}
            handleFaceRecognize={handleFaceRecognize}
            showModal={showModal}
            showLivelinessErrorModal={showLivelinessErrorModal}
          />
        </>
      )}
      {Boolean(markEmployeeDetails) && (
        <MarkEmployeeDetails
          open={Boolean(markEmployeeDetails)}
          handleCloseModal={() => setMarkEmployeeDetails(null)}
          data={markEmployeeDetails}
          img={img}
          handleCheckClick={handleCheckClick}
          handleLeaveFromEmployee={handleLeaveFromEmployee}
          manualEmpId={manualEmpId}
          memoizedAddress={memoizedAddress}
          locationLoading={locationLoading}
          latitude={latitude}
          longitude={longitude}
        />
      )}

      {showModal && (
        <ConfirmationModal
          open={showModal}
          title={modalText.title}
          message={modalText.message}
          isSuccess={modalText.isSuccess}
          handleCloseModal={() => setShowModal(false)}
        />
      )}

      {showLivelinessErrorModal && (
        <ConfirmationModal
          open={showLivelinessErrorModal}
          title="We couldn't recognize your face."
          message="Liveliness detection failed. Spoof detected. Kindly look directly into the camera."
          isSuccess={false}
          handleCloseModal={() => setShowLivelinessErrorModal(false)}
        />
      )}

      {showLivelinessSuccessModal && (
        <div className={`modal-container ${modalAnimationClass}`}>
          <div className="modal-success">
            <div className="flex justify-center">
              <img src={SuccessGIF} alt="Success" width="100" height="100" />
            </div>
            <h3>🎉 Liveliness check passed successfully 🎉</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
